import { inject, Injectable } from '@angular/core';
import { StrapiSuperProductsGroupCode } from '../enums/strapi-super-products-group-code.enum';
import { CartProduct } from '../models/cart-product.interface';
import { HolaProduct } from '../models/hola-product.interface';
import { Order } from '../models/order.interface';
import { SuperProduct } from '../models/super-product.interface';
import { GlobalReferenceService } from '../services/window-reference.service';
import { GtmEventType } from './gtm-event-type.enum';
import {
  GtmCheckoutEvent,
  GtmEvent,
  GtmPurchaseActionField,
} from './gtm-event.interface';

// export enum GtmCustomEvent {
//   '',
// }

@Injectable({ providedIn: 'root' })
export class GtmDataLayerService {
  private globalReferenceService = inject(GlobalReferenceService);

  public impressions(
    products: HolaProduct[] | SuperProduct[],
    superProductGroupCode: StrapiSuperProductsGroupCode,
  ): void {
    const event: GtmEvent = {
      event: GtmEventType.Impressions,
      ecommerce: {
        impressions: {
          currencyCode: 'EUR',
          products: products.map((p, index) => ({
            id: p.analyticsId,
            name: p.analyticsName,
            price: p?.reducedPrice?.toFixed(2),
            category: p.analyticsCategory,
            list: superProductGroupCode,
            position: index,
          })),
        },
      },
    };
    this.push(event);
  }

  public productDetail(product: HolaProduct | SuperProduct): void {
    const event: GtmEvent = {
      event: GtmEventType.ProductDetail,
      ecommerce: {
        detail: {
          products: [
            {
              id: product.analyticsId,
              name: product.analyticsName,
              price: product?.reducedPrice?.toFixed(2),
              category: product.analyticsCategory,
            },
          ],
        },
      },
    };
    this.push(event);
  }

  public productClick(
    product: HolaProduct | SuperProduct,
    superProductGroupCode: StrapiSuperProductsGroupCode,
    position: number,
  ): void {
    const event: GtmEvent = {
      event: GtmEventType.ProductClick,
      ecommerce: {
        click: {
          actionField: { list: superProductGroupCode },
          products: [
            {
              id: product.analyticsId,
              name: product.analyticsName,
              price: product?.reducedPrice?.toFixed(2),
              category: product.analyticsCategory,
              position: position,
            },
          ],
        },
      },
    };
    this.push(event);
  }

  public addToCart(cartProducts: CartProduct[]): void {
    const event: GtmEvent = {
      event: GtmEventType.AddToCart,
      ecommerce: {
        add: {
          products: cartProducts.map((cartProduct) => ({
            name: cartProduct.product.name,
            id: cartProduct.product.code,
            price: cartProduct.product.reducedPrice?.toFixed(2) || '',
            category: cartProduct.superProduct?.analyticsCategory || '',
            quantity: 1,
          })),
        },
      },
    };
    this.push(event);
  }

  public removeFromCart(cartProducts: CartProduct[]): void {
    const event: GtmEvent = {
      event: GtmEventType.RemoveFromCart,
      ecommerce: {
        remove: {
          products: cartProducts.map((cartProduct) => ({
            name: cartProduct.product.name,
            id: cartProduct.product.code,
            price: cartProduct.product.reducedPrice?.toFixed(2) || '',
            category: cartProduct.superProduct?.analyticsCategory || '',
            quantity: 1,
          })),
        },
      },
    };
    this.push(event);
  }

  public purchase(cartProducts: CartProduct[], order: Order): void {
    const event: GtmEvent = {
      event: GtmEventType.Purchase,
      ecommerce: {
        purchase: {
          actionField: {
            id: order.orderCode,
            revenue: order.finalAmount,
            tax: order.items
              .map((item) => item.taxAmount)
              .reduce((acc, val) => (acc += val), 0),
            shipping: 0,
            coupon: order.items
              .map((item) =>
                item.itemDiscounts.map((discount) => discount.discountCode),
              )
              .flat()
              .toString(),
          } as GtmPurchaseActionField,
          products: cartProducts.map((cartProduct) => ({
            name: cartProduct.product.name,
            id: cartProduct.product.code,
            price: cartProduct.product.reducedPrice?.toFixed(2) || '',
            category: cartProduct.superProduct?.analyticsCategory || '',
            quantity: 1,
          })),
        },
      },
    };
    this.push(event);
  }

  public checkout(
    cartProducts: CartProduct[],
    step: number,
    option: string,
    location?: string,
  ): void {
    const event: GtmEvent = {
      event: GtmEventType.Checkout,
      ecommerce: {
        checkout: {
          actionField: { step, option },
          products: cartProducts.map((cartProduct) => ({
            name: cartProduct.product.name,
            id: cartProduct.product.code,
            price: cartProduct.product.reducedPrice?.toFixed(2) || '',
            category: cartProduct.superProduct?.analyticsCategory || '',
            quantity: 1,
          })),
        },
      },
    };

    if (location) {
      (event.ecommerce as GtmCheckoutEvent).eventCallback = () =>
        (document.location = location);
    }

    this.push(event);
  }

  private push(event: GtmEvent): void {
    const dataLayer = this.globalReferenceService.window.dataLayer;
    if (dataLayer) {
      dataLayer.push({
        ecommerce: null,
      });
      dataLayer.push(event);
    }
  }
}
