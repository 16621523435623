import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Subject, combineLatest, switchMap } from 'rxjs';
import { ApiStatus } from '../enums/api-status.enum';
import { SuperProduct } from '../models/super-product.interface';
import { CatalogService } from './catalog.service';
import { LanguageService } from './language.service';
import { ProductDiscountService } from './prices.service';
import { StrapiProductsService } from './strapi-products.service';

interface SuperProductState {
  superProducts: SuperProduct[];
  updated: string | null;
  error: HttpErrorResponse | null;
  status: ApiStatus;
}

@Injectable({
  providedIn: 'root',
})
export class SuperProductService {
  private strapiProductsService = inject(StrapiProductsService);
  private productDiscountService = inject(ProductDiscountService);
  private catalogService = inject(CatalogService);
  private languageService = inject(LanguageService);

  private state = signal<SuperProductState>({
    superProducts: [],
    updated: null,
    error: null,
    status: ApiStatus.Loading,
  });

  public superProducts = computed(() => {
    if (
      this.catalogService.updated() &&
      this.productDiscountService.updated()
    ) {
      return this.state().superProducts.map((s) => ({
        ...s,
        activePrice:
          this.catalogService.getSuperProductLowestPriceProduct(s)?.activePrice,
        reducedPrice:
          this.catalogService.getSuperProductLowestPriceProduct(s)
            ?.reducedPrice,
        fixDiscount:
          this.catalogService.getSuperProductLowestPriceProduct(s)?.fixDiscount,
        percentageDiscount:
          this.catalogService.getSuperProductLowestPriceProduct(s)
            ?.percentageDiscount,
      }));
    }
    return this.state().superProducts;
  });

  public error = computed(() => this.state().error);
  public status = computed(() => this.state().status);
  public updated = computed(() => this.state().updated);

  private changeLanguage$ = toObservable(this.languageService.language);
  private load$ = new Subject<void>();

  constructor() {
    combineLatest([this.changeLanguage$, this.load$])
      .pipe(
        switchMap(([language]) =>
          this.strapiProductsService.loadSuperProducts(language),
        ),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (superProducts) =>
          this.state.update(
            (state) =>
              ({
                ...state,
                superProducts,
                updated: new Date().toISOString(),
                status: ApiStatus.Success,
              }) as SuperProductState,
          ),
        error: (error) =>
          this.state.update((state) => ({
            ...state,
            error,
            status: ApiStatus.Error,
          })),
      });
  }

  public load() {
    this.load$.next();
  }

  public getSuperProductByCode(code: string): SuperProduct {
    return (
      this.superProducts().filter(
        (superProduct) => superProduct.code === code,
      )[0] || ({} as SuperProduct)
    );
  }

  public getSuperProductPriceByCode(code: string): number {
    const superProduct = this.getSuperProductByCode(code);
    return this.catalogService.getSuperProductLowestPriceProduct(superProduct)
      ?.activePrice;
  }
}
