import { AppLanguage } from '../enums/app-language.enum';
import { ProductLanguageCode } from '../enums/product-language-code.enum';

const appLanguageProductLanguage = {
  [AppLanguage.CA]: ProductLanguageCode.CAT,
  [AppLanguage.EN]: ProductLanguageCode.ENG,
  [AppLanguage.DE]: ProductLanguageCode.DEU,
  [AppLanguage.ES]: ProductLanguageCode.ESP,
  [AppLanguage.FR]: ProductLanguageCode.FRA,
  [AppLanguage.IT]: ProductLanguageCode.ITA,
};

export function toProductLanguageCode(
  appLanguage: AppLanguage,
): ProductLanguageCode {
  return appLanguageProductLanguage[appLanguage] || ProductLanguageCode.ENG;
}

export function toAppLanguage(
  productLanguageCode: ProductLanguageCode,
): AppLanguage {
  return (
    (Object.entries(appLanguageProductLanguage).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => value === productLanguageCode,
    )[0]?.[0] as AppLanguage) || AppLanguage.EN
  );
}
