import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Subject, combineLatest, switchMap } from 'rxjs';
import { ApiStatus } from '../enums/api-status.enum';
import { ProductVariant } from '../models/product-variant.interface';
import { Product } from '../models/product.interface';
import { LanguageService } from './language.service';
import { StrapiProductsService } from './strapi-products.service';

interface ProductState {
  products: Product[];
  productVariants: ProductVariant[];
  packs: ProductVariant[];
  updated: string | null;
  error: HttpErrorResponse | null;
  status: ApiStatus;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private strapiProductsService = inject(StrapiProductsService);
  private languageService = inject(LanguageService);

  private state = signal<ProductState>({
    products: [],
    productVariants: [],
    packs: [],
    updated: null,
    error: null,
    status: ApiStatus.Loading,
  });

  public products = computed(() => this.state().products);
  public productVariants = computed(() => this.state().productVariants);
  public packs = computed(() => this.state().packs);
  public error = computed(() => this.state().error);
  public status = computed(() => this.state().status);
  public updated = computed(() => this.state().updated);

  private changeLanguage$ = toObservable(this.languageService.language);
  private load$ = new Subject<void>();

  constructor() {
    combineLatest([this.changeLanguage$, this.load$])
      .pipe(
        switchMap(([language]) =>
          combineLatest([
            this.strapiProductsService.loadProducts(language),
            this.strapiProductsService.loadProductVariants(language),
          ]),
        ),
        // tap((response) => console.log('product::load', response)),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: ([products, productVariants]) =>
          this.state.update(
            (state) =>
              ({
                ...state,
                products,
                productVariants,
                packs: [],
                updated: new Date().toISOString(),
                status: ApiStatus.Success,
              }) as ProductState,
          ),
        error: (error) =>
          this.state.update((state) => ({
            ...state,
            error,
            status: ApiStatus.Error,
          })),
      });
  }

  public load() {
    this.load$.next();
  }
}
