import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Subject, combineLatest, concatMap, tap } from 'rxjs';
import { ApiStatus } from '../enums/api-status.enum';
import { StrapiSuperProductsGroupCode } from '../enums/strapi-super-products-group-code.enum';
import { SuperProductsGroupProductType } from '../enums/super-products-group-product-type.enum';
import { CatalogProduct } from '../models/catalog.interface';
import { SuperProductsGroup } from '../models/super-products-group.interface';
import { maxValue, minValue } from '../utils/array.utils';
import { CatalogService } from './catalog.service';
import { LanguageService } from './language.service';
import { ProductDiscountService } from './prices.service';
import { StrapiProductsService } from './strapi-products.service';
import { SuperProductService } from './super-product.service';

interface SuperProductsGroupState {
  superProductsGroups: SuperProductsGroup[];
  updated: string | null;
  error: HttpErrorResponse | null;
  status: ApiStatus;
}

@Injectable({
  providedIn: 'root',
})
export class SuperProductsGroupService {
  private strapiProductsService = inject(StrapiProductsService);
  private productDiscountService = inject(ProductDiscountService);
  private superProductService = inject(SuperProductService);
  private catalogService = inject(CatalogService);
  private languageService = inject(LanguageService);

  private state = signal<SuperProductsGroupState>({
    superProductsGroups: [],
    updated: null,
    error: null,
    status: ApiStatus.Loading,
  });

  public superProductsGroups = computed(() => {
    if (
      this.catalogService.updated() &&
      this.productDiscountService.updated()
    ) {
      return this.state().superProductsGroups.map((superProductsGroup) => ({
        ...superProductsGroup,
        activePrice: this.getSuperProductsGroupActivePrice(superProductsGroup),
        reducedPrice:
          this.getSuperProductsGroupReducedPrice(superProductsGroup),
        fixDiscount: this.getSuperProductsGroupFixDiscount(superProductsGroup),
        percentageDiscount:
          this.getSuperProductsGroupPercentageDiscount(superProductsGroup),
      }));
    }
    return this.state().superProductsGroups;
  });

  public error = computed(() => this.state().error);
  public status = computed(() => this.state().status);
  public updated = computed(() => this.state().updated);

  private superProducts = this.superProductService.superProducts;

  private changeLanguage$ = toObservable(this.languageService.language);
  private load$ = new Subject<StrapiSuperProductsGroupCode>();

  constructor() {
    // effect(() => console.log('grupos', this.state().superProductsGroups));
    combineLatest([this.changeLanguage$, this.load$])
      .pipe(
        concatMap(([language, superProductsGroupCode]) =>
          this.strapiProductsService.loadSuperProductsGroup(
            superProductsGroupCode,
            language,
          ),
        ),
        tap((strapiSuperProductsGroup) =>
          strapiSuperProductsGroup.products.map((p) => {
            if (p.type === SuperProductsGroupProductType.SuperProductGroup) {
              this.load(p.code as StrapiSuperProductsGroupCode);
            }
          }),
        ),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (superProductsGroup) =>
          this.state.update(
            (state) =>
              ({
                ...state,
                superProductsGroups:
                  this.updateSuperProductsGroup(superProductsGroup),
                updated: new Date().toISOString(),
                status: ApiStatus.Success,
              }) as SuperProductsGroupState,
          ),
        error: (error) =>
          this.state.update((state) => ({
            ...state,
            error,
            status: ApiStatus.Error,
          })),
      });

    // effect(() => console.log('grupos', this.superProductsGroups()));
  }

  public load(code: StrapiSuperProductsGroupCode) {
    if (
      !this.superProductsGroups().find(
        (superProductsGroup) => superProductsGroup.code === code,
      )
    ) {
      this.load$.next(code);
    }
  }

  public getSuperProductsGroupByCode(code: string): SuperProductsGroup {
    const superProductsGroup = this.superProductsGroups().filter(
      (superProductGroup) => superProductGroup.code === code,
    )[0];

    if (!superProductsGroup) {
      this.load(code as StrapiSuperProductsGroupCode);
      return {} as SuperProductsGroup;
    } else {
      return superProductsGroup;
    }
  }

  public getSuperProductsGroupPriceByCode(
    code: StrapiSuperProductsGroupCode,
  ): number {
    const superProductGroup = this.getSuperProductsGroupByCode(code);
    return this.getSuperProductsGroupActivePrice(superProductGroup);
  }

  private updateSuperProductsGroup(
    superProductsGroup: SuperProductsGroup,
  ): SuperProductsGroup[] {
    const exists =
      this.superProductsGroups().filter(
        (s) => s.code === superProductsGroup.code,
      )?.length > 0;
    if (exists) {
      return this.superProductsGroups();
    } else {
      return [...this.superProductsGroups(), superProductsGroup];
    }
  }

  private getSuperProductsGroupLowestPriceProducts(
    superProductGroup: SuperProductsGroup,
  ): CatalogProduct[] {
    const superProductsCodes = superProductGroup.products
      .filter(
        (superProductGroupProduct) =>
          superProductGroupProduct.type ===
          SuperProductsGroupProductType.SuperProduct,
      )
      .map((superProductGroupProduct) => superProductGroupProduct.code);
    const superProducts = superProductsCodes
      .map(
        (code) =>
          this.superProducts().filter(
            (superProduct) => superProduct.code === code,
          )[0],
      )
      .filter((s) => s);

    // TODO loguear cuando el superproducto es undefined
    return superProducts.map((superProduct) =>
      this.catalogService.getSuperProductLowestPriceProduct(superProduct),
    );
  }
  private getSuperProductsGroupHighestDiscountProducts(
    superProductGroup: SuperProductsGroup,
  ): CatalogProduct[] {
    const superProductsCodes = superProductGroup.products
      .filter(
        (superProductGroupProduct) =>
          superProductGroupProduct.type ===
          SuperProductsGroupProductType.SuperProduct,
      )
      .map((superProductGroupProduct) => superProductGroupProduct.code);
    const superProducts = superProductsCodes
      .map(
        (code) =>
          this.superProducts().filter(
            (superProduct) => superProduct.code === code,
          )[0],
      )
      .filter((s) => s);

    // TODO loguear cuando el superproducto es undefined
    return superProducts.map((superProduct) =>
      this.catalogService.getSuperProductHighestDiscountProduct(superProduct),
    );
  }

  private getSuperProductsGroupActivePrice(
    superProductsGroup: SuperProductsGroup,
  ): number {
    return minValue(
      this.getSuperProductsGroupLowestPriceProducts(superProductsGroup).map(
        (p) => p?.activePrice,
      ),
    );
  }
  private getSuperProductsGroupReducedPrice(
    superProductsGroup: SuperProductsGroup,
  ): number {
    return minValue(
      this.getSuperProductsGroupLowestPriceProducts(superProductsGroup).map(
        (p) => p?.reducedPrice,
      ),
    );
  }

  private getSuperProductsGroupFixDiscount(
    superProductsGroup: SuperProductsGroup,
  ): number {
    return maxValue(
      this.getSuperProductsGroupHighestDiscountProducts(superProductsGroup).map(
        (p) => p?.fixDiscount || 0,
      ),
    );
  }
  private getSuperProductsGroupPercentageDiscount(
    superProductsGroup: SuperProductsGroup,
  ): number {
    return maxValue(
      this.getSuperProductsGroupHighestDiscountProducts(superProductsGroup).map(
        (p) => p?.percentageDiscount || 0,
      ),
    );
  }
}
