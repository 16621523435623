import { EnvironmentName } from './environment-name.enum';
import { Environment } from './models/environment.interface';

export const environment: Environment = {
  name: EnvironmentName.DEV,
  appId: '4c132798',
  appKey: '8504ae3a636b155724a1c7e140ee039f',
  equipmentCode: '00998',
  strapiToken:
    'a64101d86de6b2fb3d6b97be040025d2d90bd79c71fc337eed34327e7fbb864da0c7070422910b85f05669c488ed7b4974550b99bab5482698ef2d08a9a44209d55b1b44c7517bb74a904b17bb63319bfc64b1771bf74365e7e26f19f57c45d40e68905e311ec3baf3e1a3ef42890ed8146bd72a243650bea75b40afbe7f4013',
  strapiUrl: `https://pre.c1.cloud-2.tmb.cat/cms/api`,
  widgetImageApiUrl: `https://pre.static.holabarcelona.com`,
  clientApiUrl: 'https://api.tmb.cat/v1/ecommerceresidents-pre/v2',
  tmbcApiUrl: 'https://tmbapi.tmb.cat/v3/tmbcommerceint/rest',
  redsysUrl: 'https://sis-t.redsys.es:25443/sis/realizarPago',
  imageUrl: `https://pre.c1.cloud-2.tmb.cat/cms`,
  translationApiVersion: '1.0.0',
  mockConfiguration: {
    footerProductsApi: true,
    pageApi: false,
  },
  pageCache: false,
  sessionStorageExpirationTime: 60000,
};
