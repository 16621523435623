/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '../config/window.token';

declare global {
  interface Window {
    netreviewsWidget19: string | null;
    dataLayer: unknown[];
  }
}

@Injectable({ providedIn: 'root' })
export class GlobalReferenceService {
  private readonly windowService = inject(WINDOW);
  private readonly platformIdService = inject(PLATFORM_ID);

  public isBrowser = false;

  constructor() {
    this.isBrowser = isPlatformBrowser(this.platformIdService);
  }
  get window(): Window {
    if (this.isBrowser) {
      return this.windowService;
    }
    return {} as Window;
  }
}
