export function addMonths(date: string | Date, quantity: number) {
  const dt = new Date(date);
  return new Date(dt.setMonth(dt.getMonth() + quantity));
}

export function compareGreater(date1: string | Date, date2: string | Date) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return dt1.getTime() > dt2.getTime();
}
export function compareGreaterOrEqual(
  date1: string | Date,
  date2: string | Date,
) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return dt1.getTime() >= dt2.getTime();
}
export function compareLower(date1: string | Date, date2: string | Date) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return dt1.getTime() < dt2.getTime();
}
export function compareLowerOrEqual(
  date1: string | Date,
  date2: string | Date,
) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return dt1.getTime() <= dt2.getTime();
}
export function compareEqual(date1: string | Date, date2: string | Date) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return dt1.getTime() === dt2.getTime();
}
