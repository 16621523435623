import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, shareReplay, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CatalogVersionApiResponse } from '../models/catalog-version-api-response.interface';
import { PackApi } from '../models/pack-api.interface';
import { PacksCatalog } from '../models/packs-catalog.interface';

@Injectable({
  providedIn: 'root',
})
export class PacksCatalogApiService {
  private http = inject(HttpClient);

  private businessParametersApiUrl = `${environment.clientApiUrl}/business-parameters`;
  private packsCatalogVersionApiUrl = `${this.businessParametersApiUrl}/versions`;
  private packsCatalogApiUrl = (catalogVersion: string) =>
    `${this.businessParametersApiUrl}/${catalogVersion}/catalog/packs`;

  loadPacksCatalogVersion(): Observable<CatalogVersionApiResponse[]> {
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId)
      .set('maxresults', 1);
    return this.http.get<CatalogVersionApiResponse[]>(
      this.packsCatalogVersionApiUrl,
      {
        params: httpParams,
      },
    );
  }

  loadPacksCatalog(): Observable<PacksCatalog> {
    console.log('iniciando llamada packs');
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);
    return this.loadPacksCatalogVersion().pipe(
      switchMap((version: CatalogVersionApiResponse[]) =>
        this.http
          .get<PackApi[]>(this.packsCatalogApiUrl(version[0].versionCode), {
            params: httpParams,
          })
          .pipe(map((packs) => ({ packs }) as PacksCatalog)),
      ),
      shareReplay(1),
    );
  }
}
