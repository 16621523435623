import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, switchMap } from 'rxjs';
import { ApiStatus } from '../enums/api-status.enum';
import { PacksCatalog } from '../models/packs-catalog.interface';
import { PacksCatalogApiService } from './packs-catalog-api.service';

interface PacksCatalogState {
  catalog: PacksCatalog;
  updated: string | null;
  error: HttpErrorResponse | null;
  status: ApiStatus;
}

@Injectable({
  providedIn: 'root',
})
export class PacksCatalogService {
  private packsCatalogApiService = inject(PacksCatalogApiService);

  private state = signal<PacksCatalogState>({
    catalog: { packs: [] },
    updated: null,
    error: null,
    status: ApiStatus.Loading,
  });

  public catalog = computed(() => this.state().catalog);
  public updated = computed(() => this.state().updated);
  public error = computed(() => this.state().error);
  public status = computed(() => this.state().status);

  private load$ = new Subject<void>();

  constructor() {
    this.load$
      .pipe(
        switchMap(() => this.packsCatalogApiService.loadPacksCatalog()),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (catalog) =>
          this.state.update(
            (state): PacksCatalogState => ({
              ...state,
              catalog,
              updated: new Date().toISOString(),
              status: ApiStatus.Success,
            }),
          ),
        error: (error) =>
          this.state.update(
            (state): PacksCatalogState => ({
              ...state,
              error,
              status: ApiStatus.Error,
            }),
          ),
      });
  }

  public load(): void {
    this.load$.next();
  }
}
