import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CatalogVersionApiResponse } from '../models/catalog-version-api-response.interface';
import { TicketsCatalogApi } from '../models/tickets-catalog-api.interface';
import {
  ProductWithoutVariant,
  ProductWithVariant,
  TicketsCatalog,
} from '../models/tickets-catalog.interface';

@Injectable({
  providedIn: 'root',
})
export class TicketsCatalogApiService {
  private http = inject(HttpClient);

  private businessParametersApiUrl = `${environment.clientApiUrl}/business-parameters`;
  private ticketsCatalogVersionApiUrl = `${this.businessParametersApiUrl}/versions`;
  private ticketsCatalogApiUrl = (catalogVersion: string) =>
    `${this.businessParametersApiUrl}/${catalogVersion}/catalog/product/parent-variants`;

  loadTicketsCatalogVersion(): Observable<CatalogVersionApiResponse[]> {
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId)
      .set('maxresults', 1);
    return this.http.get<CatalogVersionApiResponse[]>(
      this.ticketsCatalogVersionApiUrl,
      {
        params: httpParams,
      },
    );
  }

  loadTicketsCatalog(): Observable<TicketsCatalog> {
    // return throwError(() => new Error('Error tickets catalog'));
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId)
      .set('cod', true)
      .set('onlyVoucher', false);
    return this.loadTicketsCatalogVersion().pipe(
      switchMap((version: CatalogVersionApiResponse[]) =>
        this.http
          .get<TicketsCatalogApi>(
            this.ticketsCatalogApiUrl(version[0].versionCode),
            {
              params: httpParams,
            },
          )
          .pipe(
            map((catalog: TicketsCatalogApi) => ({
              productsWithVariants:
                catalog.productsWithVariants as ProductWithVariant[],
              productsWithoutVariants:
                catalog.productsWithoutVariants as ProductWithoutVariant[],
            })),
          ),
      ),
      shareReplay(1),
    );
  }
}
