import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  PLATFORM_ID,
  Signal,
  ViewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, Subject, take } from 'rxjs';
import { StrapiSuperProductsGroupCode } from '../../shared/enums/strapi-super-products-group-code.enum';
import { SuperProductsGroupProductType } from '../../shared/enums/super-products-group-product-type.enum';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { HolaProduct } from '../../shared/models/hola-product.interface';
import { SuperProduct } from '../../shared/models/super-product.interface';
import { SuperProductsGroupProduct } from '../../shared/models/super-products-group-product.interface';
import { SuperProductsGroup } from '../../shared/models/super-products-group.interface';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { SuperProductService } from '../../shared/services/super-product.service';
import { SuperProductsGroupService } from '../../shared/services/super-products-group.service';
import { FooterWidgetConfiguration } from './models/footer-widget-configuration.interface';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'hola-footer-widget',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './footer-widget.component.html',
  styleUrl: './footer-widget.component.scss',
})
export class FooterWidgetComponent implements AfterViewInit {
  public configuration = input.required<FooterWidgetConfiguration>();

  private readonly navigationService = inject(NavigationService);
  private readonly superProductService = inject(SuperProductService);
  private readonly superProductsGroupService = inject(
    SuperProductsGroupService,
  );

  private readonly languageService = inject(LanguageService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public superProductsGroup: Signal<SuperProductsGroup> = computed(() =>
    this.superProductsGroupService.getSuperProductsGroupByCode(
      StrapiSuperProductsGroupCode.Footer,
    ),
  );
  public superProductsGroupProducts: Signal<SuperProductsGroupProduct[]> =
    computed(
      () =>
        this.superProductsGroup()?.products?.filter((product) =>
          this.superProductService
            .superProducts()
            .find(
              (p) =>
                product.code === p.code ||
                product.type ===
                  SuperProductsGroupProductType.SuperProductGroup,
            ),
        ) || [],
    );

  public products: Signal<HolaProduct[]> = computed(() =>
    this.superProductsGroupProducts().map((p, index) => {
      const product =
        p.type === SuperProductsGroupProductType.SuperProduct
          ? this.superProductService.getSuperProductByCode(p.code)
          : this.superProductsGroupService.getSuperProductsGroupByCode(p.code);
      return {
        code: product.code,
        title: product.title,
        description: product.description,
        shortDescription: product.shortDescription,
        imageUrl: product.mainImageUrl,
        analyticsId: product.analyticsId,
        analyticsName: product.analyticsName,
        analyticsCategory: product.analyticsCategory,
        activePrice: product.activePrice,
        reducedPrice: product.reducedPrice,
        fixDiscount: product.fixDiscount,
        percentageDiscount: product.percentageDiscount,
        active: product.active,
        visible: product.visible,
        onClick:
          p.type === SuperProductsGroupProductType.SuperProduct
            ? () => {
                this.gtmDataLayerService.productClick(
                  product as SuperProduct,
                  StrapiSuperProductsGroupCode.Footer,
                  index,
                );
                this.navigationService.navigateByUrl(
                  `/${this.languageService.language()}/tickets/${(product as SuperProduct).slug}`,
                );
              }
            : () =>
                this.navigationService.navigateToSuperProductsGroup(
                  p.code as StrapiSuperProductsGroupCode,
                ),
      };
    }),
  );

  public year = new Date().getFullYear();

  public faqUrl = this.navigationService.faqUrl;
  public homeUrl = this.navigationService.homeUrl;
  public recoverUrl = this.navigationService.recoverUrl;
  public termsAndConditionsUrl = this.navigationService.termsAndConditionsUrl;
  public contactUrl = this.navigationService.contactUrl;
  public aboutUrl = this.navigationService.aboutUrl;
  public legalUrl = this.navigationService.legalUrl;
  public sitemapUrl = this.navigationService.sitemapUrl;
  public transportDealsLandingUrl =
    this.navigationService.transportDealsLandingUrl;
  public ticketsUrl = this.navigationService.ticketsUrl;

  private products$ = toObservable(this.products);
  private isIntersecting$ = new Subject<void>();

  private readonly platformIdService = inject(PLATFORM_ID);

  constructor() {
    combineLatest([
      this.products$.pipe(
        filter((p) => p?.length > 0),
        take(1),
      ),
      this.isIntersecting$,
    ])
      .pipe(take(1))
      .subscribe(([products]) =>
        this.gtmDataLayerService.impressions(
          products,
          StrapiSuperProductsGroupCode.Footer,
        ),
      );
  }

  @ViewChild('footer') footer!: ElementRef;

  ngAfterViewInit() {
    
  if (isPlatformBrowser(this.platformIdService)) {
    const threshold = 0.2; // % del elemento que está en visible
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isIntersecting$.next();
            observer.disconnect();
          }
        });
      },
      { threshold },
    );
    observer.observe(this.footer.nativeElement);
  }
  }
}
