import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, switchMap } from 'rxjs';
import { ProductDiscount } from '../models/product-discount.interface';
import { DiscountApiService } from './discount-api.service';

interface ProductDiscountState {
  productsDiscounts: ProductDiscount[];
  packsDiscounts: ProductDiscount[];
  productsDiscountsUpdated: string | null;
  packsDiscountsUpdated: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class ProductDiscountService {
  private readonly discountApiService = inject(DiscountApiService);
  private readonly state = signal<ProductDiscountState>({
    productsDiscounts: [],
    packsDiscounts: [],
    productsDiscountsUpdated: null,
    packsDiscountsUpdated: null,
  });

  private readonly loadPacksDiscounts$ = new Subject<void>();
  private readonly loadProductsDiscounts$ = new Subject<void>();

  public productsDiscounts = computed(() => this.state().productsDiscounts);
  public packsDiscounts = computed(() => this.state().packsDiscounts);
  public updated = computed(
    () =>
      this.state().packsDiscountsUpdated &&
      this.state().productsDiscountsUpdated,
  );

  constructor() {
    this.loadProductsDiscounts$
      .pipe(
        switchMap(() => this.discountApiService.loadProductsDiscounts()),
        takeUntilDestroyed(),
      )
      .subscribe((productsDiscounts) =>
        this.state.update((state) => ({
          ...state,
          productsDiscounts,
          productsDiscountsUpdated: new Date().toISOString(),
        })),
      );
    this.loadPacksDiscounts$
      .pipe(
        switchMap(() => this.discountApiService.loadPacksDiscounts()),
        takeUntilDestroyed(),
      )
      .subscribe((packsDiscounts) =>
        this.state.update((state) => ({
          ...state,
          packsDiscounts,
          packsDiscountsUpdated: new Date().toISOString(),
        })),
      );
  }

  public load(): void {
    this.loadProductsDiscounts$.next();
    this.loadPacksDiscounts$.next();
  }
}
