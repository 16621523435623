import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Subject, combineLatest, map, switchMap, tap } from 'rxjs';
import { AppLanguage } from '../enums/app-language.enum';
import { CatalogProductType } from '../enums/catalog-product-type.enum';
import { ProductLanguageCode } from '../enums/product-language-code.enum';
import {
  Catalog,
  CatalogParentProduct,
  CatalogProduct,
} from '../models/catalog.interface';
import { Pack, VariantPack } from '../models/pack.interface';
import { PacksCatalog } from '../models/packs-catalog.interface';
import { ProductDiscount } from '../models/product-discount.interface';
import { ProductPrice } from '../models/product-price.interface';
import { ProductVariant } from '../models/product-variant.interface';
import { Product } from '../models/product.interface';
import { SuperProduct } from '../models/super-product.interface';
import { Price } from '../models/tickets-catalog-api.interface';
import {
  ProductParentVariant,
  ProductWithVariant,
  ProductWithoutVariant,
  TicketsCatalog,
} from '../models/tickets-catalog.interface';
import { compareGreaterOrEqual, compareLower } from '../utils/date.utils';
import { toProductLanguageCode } from '../utils/language.util';
import { LanguageService } from './language.service';
import { PacksCatalogService } from './packs-catalog.service';
import { ProductDiscountService } from './prices.service';
import { ProductService } from './product.service';
import { TicketsCatalogService } from './tickets-catalog.service';

interface CatalogState {
  catalog: Catalog;
  updated: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private productService = inject(ProductService);
  private ticketsCatalogService = inject(TicketsCatalogService);
  private packsCatalogService = inject(PacksCatalogService);
  private productDiscountService = inject(ProductDiscountService);
  private languageService = inject(LanguageService);

  private state = signal<CatalogState>({
    catalog: {
      ticketsParentProducts: [],
      ticketsProductsWithoutVariants: [],
      packsParentProducts: [],
    },
    updated: null,
  });

  public catalog = computed(() => this.state().catalog);

  public ticketsParentProducts = computed(
    () => this.state().catalog.ticketsParentProducts,
  );
  public packsParentProducts = computed(
    () => this.state().catalog.packsParentProducts,
  );

  public ticketsVariantProducts = computed(() =>
    this.getTicketsVariantProducts(this.state().catalog),
  );
  public packsVariantProducts = computed(() =>
    this.getPacksVariantProducts(this.state().catalog),
  );

  public parentProducts = computed(() => [
    ...this.ticketsParentProducts(),
    ...this.packsParentProducts(),
  ]);
  public variantProducts = computed(() => [
    ...this.ticketsVariantProducts(),
    ...this.packsVariantProducts(),
  ]);

  public updated = computed(() => this.state().updated);

  private load$ = new Subject<void>();

  private ticketsCatalog$ = toObservable(this.ticketsCatalogService.catalog);
  private packsCatalog$ = toObservable(this.packsCatalogService.catalog);
  private language$ = toObservable(this.languageService.language);
  private productsDiscounts$ = toObservable(
    this.productDiscountService.productsDiscounts,
  );
  private packsDiscounts$ = toObservable(
    this.productDiscountService.packsDiscounts,
  );
  private productVariants$ = toObservable(this.productService.productVariants);
  private products$ = toObservable(this.productService.products);

  constructor() {
    this.load$
      .pipe(
        tap(() => this.ticketsCatalogService.load()),
        tap(() => this.packsCatalogService.load()),
        tap(() => this.productDiscountService.load()),
        tap(() => this.productService.load()),
        switchMap(() =>
          combineLatest([
            this.language$,
            this.ticketsCatalog$,
            this.packsCatalog$,
            this.productsDiscounts$,
            this.packsDiscounts$,
            this.products$,
            this.productVariants$,
          ]).pipe(
            map(
              ([
                language,
                ticketsCatalog,
                packsCatalog,
                productsDiscounts,
                packsDiscounts,
                products,
                productVariants,
              ]) =>
                this.mapCatalog(
                  ticketsCatalog,
                  packsCatalog,
                  products,
                  productVariants,
                  productsDiscounts,
                  packsDiscounts,
                  language,
                ),
            ),
          ),
        ),
        takeUntilDestroyed(),
      )
      .subscribe((catalog) =>
        this.state.update(
          (state): CatalogState => ({
            ...state,
            catalog,
            updated: new Date().toISOString(),
          }),
        ),
      );
  }

  public load(): void {
    this.load$.next();
  }

  public getCatalogProduct(code: string): CatalogProduct {
    return this.variantProducts().filter((p) => p.code === code)[0];
  }
  public getCatalogParentProduct(code: string): CatalogParentProduct {
    return this.parentProducts().filter((p) => p.code === code)[0];
  }

  public getSuperProductLowestPriceProduct(
    superProduct: SuperProduct,
  ): CatalogProduct {
    const productCodes = superProduct.subProducts || [];
    return this.getLowestPriceProduct(productCodes);
  }

  public getLowestPriceProduct(codes: string[]): CatalogProduct {
    const productsPrices: ProductPrice[] = codes
      .map((code) => {
        const parentProduct = this.parentProducts().filter(
          (p) => p.code === code,
        )[0];
        if (parentProduct) {
          return parentProduct.products.map(
            (p): ProductPrice => ({
              code: p.code,
              price: p.reducedPrice,
            }),
          );
        } else {
          const product = this.variantProducts().filter(
            (p) => p.code === code && p.reducedPrice && p.reducedPrice >= 0,
          )[0];
          return product
            ? ({
                code: product.code,
                price: product.reducedPrice,
              } as ProductPrice)
            : ({} as ProductPrice);
        }
      })
      .flat()
      .filter((p) => p?.code && p?.price);

    const lowestPriceProductCode = productsPrices.reduce(
      (acc: ProductPrice, curr) => (curr.price < acc.price ? curr : acc),
      productsPrices[0],
    )?.code;

    return this.getCatalogProduct(lowestPriceProductCode);
  }
  public getSuperProductHighestDiscountProduct(
    superProduct: SuperProduct,
  ): CatalogProduct {
    const productCodes = superProduct.subProducts || [];
    return this.getHighestDiscountProduct(productCodes);
  }

  public getHighestDiscountProduct(codes: string[]): CatalogProduct {
    const products = codes
      .map((code) => {
        const parentProduct = this.parentProducts().filter(
          (p) => p.code === code,
        )[0];
        if (parentProduct) {
          return parentProduct.products;
        } else {
          const product = this.variantProducts().filter(
            (p) =>
              p.code === code &&
              (p.fixDiscount || (p.percentageDiscount && p.activePrice >= 0)),
          )[0];
          return product;
        }
      })
      .flat()
      .filter((p) => p?.code && (p?.fixDiscount || p?.percentageDiscount));

    const productsDiscounts = products.map((product) => ({
      code: product.code,
      price: product.activePrice,
      fixDiscount: product.fixDiscount,
      percentageDiscount: product.percentageDiscount,
      total: 0,
    }));

    const highestDiscountProductCode = productsDiscounts.reduce((acc, curr) => {
      const currPercentageDiscount =
        curr.price * (curr.percentageDiscount || 0) * 0.01;
      const currFixDiscount = curr.fixDiscount || 0;
      const currDiscount =
        currPercentageDiscount > currFixDiscount
          ? currPercentageDiscount
          : currFixDiscount;

      if (currDiscount > acc.total) {
        return curr;
      }
      return acc;
    }, productsDiscounts[0])?.code;

    return this.getCatalogProduct(highestDiscountProductCode);
  }

  private mapCatalog(
    ticketsCatalog: TicketsCatalog,
    packsCatalog: PacksCatalog,
    products: Product[],
    productVariants: ProductVariant[],
    productsDiscounts: ProductDiscount[],
    packsDiscounts: ProductDiscount[],
    language = AppLanguage.EN,
  ): Catalog {
    const productLanguageCode = toProductLanguageCode(language);
    const ticketsParentProducts = this.mapTicketsParentProducts(
      ticketsCatalog.productsWithVariants,
      products,
      productVariants,
      productsDiscounts,
    );
    const ticketsProductsWithoutVariants = this.mapTicketsProducts(
      ticketsCatalog.productsWithoutVariants,
      productVariants,
      packsDiscounts,
    );

    const ticketsProducts = [
      ...ticketsParentProducts.map((p) => p.products).flat(),
      ...ticketsProductsWithoutVariants,
    ];
    const packsParentProducts = this.mapPacksParentProducts(
      packsCatalog.packs,
      productLanguageCode,
      packsDiscounts,
      ticketsProducts,
    );
    return {
      packsParentProducts: packsParentProducts,
      ticketsParentProducts: ticketsParentProducts,
      ticketsProductsWithoutVariants: ticketsProductsWithoutVariants,
    };
  }

  private mapPacksParentProducts(
    products: Pack[],
    productLanguageCode: ProductLanguageCode,
    packsDiscounts: ProductDiscount[],
    ticketsProducts: CatalogProduct[],
  ): CatalogParentProduct[] {
    return products.map((product) =>
      this.mapPackParentProduct(
        product,
        productLanguageCode,
        packsDiscounts,
        ticketsProducts,
      ),
    );
  }

  private mapPackParentProduct(
    product: Pack,
    productLanguageCode: ProductLanguageCode,
    packsDiscounts: ProductDiscount[],
    ticketsProducts: CatalogProduct[],
  ): CatalogParentProduct {
    const packLanguage = product.packLanguages.filter(
      (packLanguage) => packLanguage.code === productLanguageCode,
    )[0];
    return {
      code: product.code,
      name: packLanguage?.name,
      type: CatalogProductType.PackProduct,
      products: this.mapPackVariants(
        product.variantsPack,
        productLanguageCode,
        packsDiscounts,
        ticketsProducts,
      ),
    };
  }

  private mapPackVariants(
    products: VariantPack[],
    productLanguageCode: ProductLanguageCode,
    packsDiscounts: ProductDiscount[],
    ticketsProducts: CatalogProduct[],
  ): CatalogProduct[] {
    return products.map((v) =>
      this.mapPackVariant(
        v,
        productLanguageCode,
        packsDiscounts,
        ticketsProducts,
      ),
    );
  }

  private mapPackVariant(
    product: VariantPack,
    productLanguageCode: ProductLanguageCode,
    packsDiscounts: ProductDiscount[],
    ticketsProducts: CatalogProduct[],
  ): CatalogProduct {
    const variantPackLanguage = product.variantPackLanguages.filter(
      (packLanguage) => packLanguage.code === productLanguageCode,
    )[0];
    const discount = packsDiscounts.filter(
      (packDiscount) => packDiscount.code === product.code,
    )[0];
    const activePrice = ticketsProducts
      .filter((ticketProduct) =>
        product.productsVariantPack.find(
          (p) => p.productCode === ticketProduct.code,
        ),
      )
      .map((ticketProduct) => ticketProduct.activePrice)
      .reduce((acc, curr) => acc + curr, 0);
    return {
      code: product.code,
      activePrice: activePrice,
      reducedPrice: discount?.price || 0,
      fixDiscount: discount?.fixDiscount || 0,
      percentageDiscount: discount?.percentageDiscount || 0,
      type: CatalogProductType.PackProduct,
      name: variantPackLanguage?.name,
      shortName: variantPackLanguage?.shortName,
      description: variantPackLanguage?.description,
    };
  }

  private mapTicketsParentProducts(
    productsWithVariant: ProductWithVariant[],
    products: Product[],
    productVariants: ProductVariant[],
    discounts: ProductDiscount[],
  ): CatalogParentProduct[] {
    const parentProducts = productsWithVariant
      .map((product) => product.productParentVariants)
      .flat();
    return parentProducts.map((parentProduct) =>
      this.mapTicketsParentProduct(
        parentProduct,
        products,
        productVariants,
        discounts,
      ),
    );
  }

  private mapTicketsParentProduct(
    productParentVariant: ProductParentVariant,
    products: Product[],
    productVariants: ProductVariant[],
    discounts: ProductDiscount[],
  ): CatalogParentProduct {
    return {
      code: productParentVariant.variantFieldGroupingValue,
      type: CatalogProductType.TicketProduct,
      name: products.filter(
        (product) =>
          product.code === productParentVariant.variantFieldGroupingValue,
      )[0]?.title,
      products: this.mapTicketsProducts(
        productParentVariant.products,
        productVariants,
        discounts,
      ),
    };
  }

  private mapTicketsProducts(
    products: ProductWithoutVariant[],
    productVariants: ProductVariant[],
    discounts: ProductDiscount[],
  ): CatalogProduct[] {
    return products.map((product) =>
      this.mapTicketsProduct(product, productVariants, discounts),
    );
  }

  private mapTicketsProduct(
    product: ProductWithoutVariant,
    productVariants: ProductVariant[],
    discounts: ProductDiscount[],
  ): CatalogProduct {
    const productVariant = productVariants.filter(
      (p) => p.code === product.code,
    )[0];
    const discount = discounts.filter(
      (productDiscount) => productDiscount.code === product.code,
    )[0];

    return {
      code: product.code,
      type: CatalogProductType.TicketProduct,
      activePrice: this.getActivePrice(product.prices),
      name: productVariant?.title,
      shortName: productVariant?.shortTitle,
      description: productVariant?.description,
      reducedPrice: discount?.price || 0,
      fixDiscount: discount?.fixDiscount || 0,
      percentageDiscount: discount?.percentageDiscount || 0,
    };
  }

  // private addTicketsParentProductsPrice(
  //   parentProducts: CatalogParentProduct[],
  //   prices: ProductPrice[],
  // ): CatalogParentProduct[] {
  //   return parentProducts.map((parentProduct) => ({
  //     ...parentProduct,
  //     products: this.addTicketsProductsPrice(parentProduct.products, prices),
  //   }));
  // }

  // private addTicketsProductsPrice(
  //   products: CatalogProduct[],
  //   prices: ProductPrice[],
  // ): CatalogProduct[] {
  //   return products.map((product) =>
  //     this.addTicketsProductPrice(product, prices),
  //   );
  // }

  // private addTicketsProductPrice(
  //   product: CatalogProduct,
  //   prices: ProductPrice[],
  // ): CatalogProduct {
  //   return {
  //     ...product,
  //     reducedPrice:
  //       prices.filter(
  //         (productDiscount) => productDiscount.code === product.code,
  //       )[0].price || 0,
  //   };
  // }

  private getActivePrice(prices: Price[]): number {
    const currentDate = new Date();
    return (
      prices.filter(
        (price) =>
          compareGreaterOrEqual(currentDate, price.activationDatetime) &&
          (!price.endActivationDatetime ||
            compareLower(currentDate, price.endActivationDatetime)),
      )[0]?.price || 0
    );
  }

  private getTicketsVariantProducts(catalog: Catalog): CatalogProduct[] {
    const parentProductsCatalogProducts = catalog.ticketsParentProducts
      .map((parentProduct) => parentProduct.products.map((product) => product))
      .flat();
    const productsWithoutVariantsCatalogProducts =
      catalog.ticketsProductsWithoutVariants.map((product) => product);
    return [
      ...parentProductsCatalogProducts,
      ...productsWithoutVariantsCatalogProducts,
    ];
  }
  private getPacksVariantProducts(catalog: Catalog): CatalogProduct[] {
    return catalog.packsParentProducts.map((pack) => pack.products).flat();
  }
}
