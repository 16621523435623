<footer #footer>
  <div class="container-fluid">
    <div class="row">
      <div class="container container-footer">
        <div class="footer-body row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-3">
            <div class="row">
              <a class="navbar-brand" [routerLink]="homeUrl()"
                ><img
                  src="/assets/images/footer-widget/logo-alpha.png"
                  alt=""
                  class="img-producto img-fluid logo-footer"
              /></a>
            </div>
            <div class="row">
              <div class="center-footer-icons">
                <a
                  href="//www.facebook.com/holabarcelona.travelsolution"
                  target="_blank"
                  title="Facebook"
                  rel="nofollow"
                  ><div class="icon-footer facebook-ico"></div
                ></a>
                <a
                  href="//www.instagram.com/holabarcelona.travelsolution/"
                  target="_blank"
                  title="Instagram"
                  rel="nofollow"
                  ><div class="icon-footer instagram-ico"></div
                ></a>
                <a
                  href="//www.youtube.com/channel/UCzNEErda7tdJBlbj_s7OJKw"
                  target="_blank"
                  title="Youtube"
                  rel="nofollow"
                  ><div class="icon-footer youtube-ico"></div
                ></a>
              </div>
            </div>
          </div>
          <!--Information and help-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-3 footer-des">
            <a href="#" class="title-footer-link">{{
              "template.footer.customer_service.title" | translate
            }}</a>
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="faqUrl()"
              title="{{ 'template.footer.smart_moving.faq' | translate }}"
              ><div class="question-ico"></div>
              {{ "template.footer.smart_moving.faq" | translate }}</a
            >
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="recoverUrl()"
              title="{{ 'template.voucher_recover.title' | translate }}"
              ><div class="voucher-ico"></div>
              {{ "template.voucher_recover.title" | translate }}</a
            >
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="termsAndConditionsUrl()"
              title="{{
                'template.footer.customer_service.terms_conditions' | translate
              }}"
              ><div class="info-ico"></div>
              {{
                "template.footer.customer_service.terms_conditions" | translate
              }}</a
            >
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="contactUrl()"
              title="{{ 'template.footer.contact.title' | translate }}"
              ><div class="contacto-ico"></div>
              {{ "template.footer.contact.title" | translate }}</a
            >
          </div>
          <!--Information and help-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-3 footer-des">
            <a href="#" class="title-footer-link">{{
              "template.footer.smart_moving.title" | translate
            }}</a>
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="aboutUrl()"
              title="{{ 'template.footer.smart_moving.about_us' | translate }}"
              >{{ "template.footer.smart_moving.about_us" | translate }}</a
            >
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="legalUrl()"
              title="{{ 'template.footer.customer_service.legal' | translate }}"
              >{{ "template.footer.customer_service.legal" | translate }}</a
            >
            <a
              class="regular-footer-link"
              target="_blank"
              rel="nofollow"
              href="{{ 'template.privacy_policy.link' | translate }}"
              title="{{ 'template.privacy_policy.title' | translate }}"
              >{{ "template.privacy_policy.title" | translate }}</a
            >
            <a
              class="regular-footer-link"
              target="_blank"
              rel="nofollow"
              href="{{
                'template.footer.customer_service.cookies_link' | translate
              }}"
              title="{{
                'template.footer.customer_service.cookies' | translate
              }}"
              >{{ "template.footer.customer_service.cookies" | translate }}</a
            >
            <!--TODO: Enlace cookiebot-->
            <a
              class="regular-footer-link"
              rel="nofollow"
              href="#"
              title="{{
                'template.footer.customer_service.cookies_manager' | translate
              }}"
              >{{
                "template.footer.customer_service.cookies_manager" | translate
              }}</a
            >
            <a
              class="regular-footer-link"
              rel="nofollow"
              [routerLink]="sitemapUrl()"
              title="{{ 'template.sitemap.title' | translate }}"
              >{{ "template.sitemap.title" | translate }}</a
            >
          </div>
          <!-- Products -->
          <div class="col-12 col-sm-12 col-md-12 col-lg-3 footer-des">
            <a href="#" class="title-footer-link">{{
              "template.footer.products.title" | translate
            }}</a>
            @for (product of products(); track $index) {
              <a
                class="regular-footer-link footer_event_buy"
                (click)="product?.onClick()"
                (keydown)="product?.onClick()"
                tabindex=""
                title="{{ product.title }}"
              >
                {{ product.title }}
              </a>
            }
            <a
              class="regular-footer-link footer_event_buy"
              [routerLink]="transportDealsLandingUrl()"
              title="{{ 'template.header.tickets.packs.name' | translate }}"
            >
              {{ "template.header.tickets.packs.name" | translate }}
            </a>
            <!--TODO: La ruta "/en/tickets/tours-from-barcelona" de la distribuidora de excursiones está en pageid.Ticket, esto habría que revisarlo-->
            <a
              class="regular-footer-link footer_event_buy"
              [routerLink]="ticketsUrl()"
              title="{{
                'template.header.tickets.bus_turistic_catalunya.name'
                  | translate
              }}"
            >
              {{
                "template.header.tickets.bus_turistic_catalunya.name"
                  | translate
              }}
            </a>
          </div>
          <div
            id="accordion-footer"
            class="row-accordion accordion col-12 footer-mob"
          >
            <div class="card">
              <div class="card-header">
                <a
                  class="card-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-footer1"
                  aria-expanded="false"
                  aria-controls="#collapse-footer1"
                >
                  {{ "template.footer.customer_service.title" | translate }}
                </a>
              </div>
              <div
                id="collapse-footer1"
                class="collapse"
                data-parent="#accordion-faqs"
              >
                <div class="card-body">
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="faqUrl()"
                    title="{{ 'template.footer.smart_moving.faq' | translate }}"
                    ><div class="question-ico"></div>
                    {{ "template.footer.smart_moving.faq" | translate }}</a
                  >
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="recoverUrl()"
                    title="{{ 'template.voucher_recover.title' | translate }}"
                    ><div class="voucher-ico"></div>
                    {{ "template.voucher_recover.title" | translate }}</a
                  >
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="termsAndConditionsUrl()"
                    title="{{
                      'template.footer.customer_service.terms_conditions'
                        | translate
                    }}"
                    ><div class="info-ico"></div>
                    {{
                      "template.footer.customer_service.terms_conditions"
                        | translate
                    }}</a
                  >
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="contactUrl()"
                    title="{{ 'template.footer.contact.title' | translate }}"
                    ><div class="contacto-ico"></div>
                    {{ "template.footer.contact.title" | translate }}</a
                  >
                </div>
              </div>
              <div class="card-header">
                <a
                  class="card-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-footer2"
                  aria-expanded="false"
                  aria-controls="#collapse-footer2"
                >
                  {{ "template.footer.smart_moving.title" | translate }}
                </a>
              </div>
              <div
                id="collapse-footer2"
                class="collapse"
                data-parent="#accordion-faqs"
              >
                <div class="card-body">
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="aboutUrl()"
                    title="{{
                      'template.footer.smart_moving.about_us' | translate
                    }}"
                    >{{
                      "template.footer.smart_moving.about_us" | translate
                    }}</a
                  >
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="legalUrl()"
                    title="{{
                      'template.footer.customer_service.legal' | translate
                    }}"
                    >{{
                      "template.footer.customer_service.legal" | translate
                    }}</a
                  >
                  <a
                    class="regular-footer-link"
                    target="_blank"
                    rel="nofollow"
                    href="{{ 'template.privacy_policy.link' | translate }}"
                    title="{{ 'template.privacy_policy.title' | translate }}"
                    >{{ "template.privacy_policy.title" | translate }}</a
                  >
                  <a
                    class="regular-footer-link"
                    target="_blank"
                    rel="nofollow"
                    href="{{
                      'template.footer.customer_service.cookies_link'
                        | translate
                    }}"
                    title="{{
                      'template.footer.customer_service.cookies' | translate
                    }}"
                    >{{
                      "template.footer.customer_service.cookies" | translate
                    }}</a
                  >
                  <!--TODO: Enlace cookiebot-->
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    href="#"
                    title="{{
                      'template.footer.customer_service.cookies_manager'
                        | translate
                    }}"
                    >{{
                      "template.footer.customer_service.cookies_manager"
                        | translate
                    }}</a
                  >
                  <a
                    class="regular-footer-link"
                    rel="nofollow"
                    [routerLink]="sitemapUrl()"
                    title="{{ 'template.sitemap.title' | translate }}"
                    >{{ "template.sitemap.title" | translate }}</a
                  >
                </div>
              </div>
              <div class="card-header">
                <a
                  class="card-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-footer3"
                  aria-expanded="false"
                  aria-controls="#collapse-footer3"
                >
                  {{ "template.footer.products.title" | translate }}
                </a>
              </div>
              <div
                id="collapse-footer3"
                class="collapse"
                data-parent="#accordion-faqs"
              >
                <div class="card-body">
                  @for (product of products(); track $index) {
                    <a
                      class="regular-footer-link footer_event_buy comprar_footer_{{
                        product.code
                      }}"
                      (click)="product?.onClick()"
                      (keydown)="product?.onClick()"
                      tabindex=""
                      title="{{ product.title }}"
                    >
                      {{ product.title }}
                    </a>
                  }
                  <a
                    class="regular-footer-link footer_event_buy"
                    [routerLink]="transportDealsLandingUrl()"
                    title="{{
                      'template.header.tickets.packs.name' | translate
                    }}"
                  >
                    {{ "template.header.tickets.packs.name" | translate }}
                  </a>
                  <!--TODO: Lo mismo que en el otro enlace de excursiones-->
                  <a
                    class="regular-footer-link footer_event_buy"
                    [routerLink]="ticketsUrl()"
                    title="{{
                      'template.header.tickets.bus_turistic_catalunya.name'
                        | translate
                    }}"
                  >
                    {{
                      "template.header.tickets.bus_turistic_catalunya.name"
                        | translate
                    }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-footer">
          <div class="row ordered-footer">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 order-one">
              <img
                src="/assets/images/footer-widget/corp-tmb-logo-small.png"
                alt=""
                class="img-producto img-fluid"
              />
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 order-two">
              <!--TODO revisar translate con parámetros-->
              <p class="txt-legal">
                {{
                  "template.footer.copyright" | translate: { currentYear: year }
                }}
              </p>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 order-three">
              <img
                src="/assets/images/footer-widget/visas.jpg"
                alt=""
                class="img-producto img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- footer -->
