import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order } from '../models/order.interface';
import { PackDiscountApi } from '../models/pack-discount-api.interface';
import { ProductDiscountApi } from '../models/product-discount-api.interface';
import { ProductDiscount } from '../models/product-discount.interface';
import { DiscountApplied } from './order.service';

export interface OrderDiscount {
  discountCoupons: string[];
  notValidDiscountCoupons: string[];
  discountsApplied: DiscountApplied[];
  order: Order;
  verifiedDiscounts: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DiscountApiService {
  private http = inject(HttpClient);
  private discountsApiUrl = `${environment.clientApiUrl}/sell-service/check-discounts`;
  private productsDiscountsApiUrl = `${environment.clientApiUrl}/sell-service/products-discounts`;
  private packsDiscountsApiUrl = `${environment.clientApiUrl}/sell-service/packs-discounts`;

  public checkDiscounts(
    discountCoupons: string[],
    order: Order,
  ): Observable<OrderDiscount> {
    const httpParams = new HttpParams()
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);

    return this.http
      .put<OrderDiscount>(
        this.discountsApiUrl,
        { discountCoupons, order },
        { params: httpParams },
      )
      .pipe(
        map((response) => ({
          ...response,
          notValidDiscountCoupons: response.notValidDiscountCoupons || [],
        })),
      );
  }

  public loadProductsDiscounts(): Observable<ProductDiscount[]> {
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);

    return this.http
      .get<ProductDiscountApi[]>(this.productsDiscountsApiUrl, {
        params: httpParams,
      })
      .pipe(
        map((productDiscounts) =>
          productDiscounts.map(
            (p) =>
              ({
                code: p.productCode,
                price: p.productPrice,
                fixDiscount: p.fixTotalDiscount,
                percentageDiscount: p.percentageTotalDiscount,
              }) as ProductDiscount,
          ),
        ),
      );
  }

  public loadPacksDiscounts(): Observable<ProductDiscount[]> {
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);

    return this.http
      .get<PackDiscountApi[]>(this.packsDiscountsApiUrl, {
        params: httpParams,
      })
      .pipe(
        map((packsDiscounts) =>
          packsDiscounts.map(
            (p) =>
              ({
                code: p.packCode,
                price: p.packPrice,
                fixDiscount: 0,
                percentageDiscount: p.percentageTotalDiscount,
              }) as ProductDiscount,
          ),
        ),
      );
  }
}
