import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppLanguage } from '../enums/app-language.enum';
import { StrapiSuperProductsGroupCode } from '../enums/strapi-super-products-group-code.enum';
import { SuperProductsGroupProductType } from '../enums/super-products-group-product-type.enum';
import { ModuleBox } from '../models/module-box.interface';
import { ProductVariant } from '../models/product-variant.interface';
import { Product } from '../models/product.interface';
import {
  StrapiGroupEntityData,
  StrapiImageData,
  StrapiModuleBoxData,
  StrapiProductData,
  StrapiProductVariantData,
  StrapiProductVariants,
  StrapiProducts,
  StrapiSuperProduct,
  StrapiSuperProductChannelData,
  StrapiSuperProductsChannel,
  StrapiSuperProductsGroupData,
  StrapiSuperProductsGroupMisData,
  StrapiSuperProductsGroups,
  StrapiSuperProductsGroupsMis,
} from '../models/strapi.interface';
import { SuperProduct } from '../models/super-product.interface';
import { SuperProductsGroupProduct } from '../models/super-products-group-product.interface';
import { SuperProductsGroup } from '../models/super-products-group.interface';

@Injectable({
  providedIn: 'root',
})
export class StrapiProductsService {
  private httpClient = inject(HttpClient);

  private superProductsGroupsApiUrl = `${environment.strapiUrl}/super-products-groups?populate[group_entities][populate]=*&populate[image][populate]=*`;
  private superProductsGroupApiUrl = (code: StrapiSuperProductsGroupCode) =>
    `${environment.strapiUrl}/super-products-groups?populate[group_entities][populate]=*&populate[image][populate]=*&filters[code]=${code}`;

  private channelSuperProductsApiUrl = (date: string) =>
    `${environment.strapiUrl}/super-product-channels?filters[channel][name]=ECNR%20WEB&populate[super_product]=*&filters[startingDate][$lte]=${date}&filters[$or][0][endingDate][$gt]=${date}&filters[$or][1][endingDate][$null]=true`;

  private superProductApiUrl = (
    superProductCode: string,
    locale: AppLanguage,
  ) =>
    `${environment.strapiUrl}/super-products?locale=${locale}&filters[code]=${superProductCode}&populate[ModuleBoxes][populate]=Images&populate[images][populate]=*&populate[mainImage][populate]=*&populate[headerImage][populate]=*&populate[moduleAccordions][populate]=*&populate[moduleLinkDownloads][populate]=*`;

  private superProductProductsApiUrl = (superProductCode: string) =>
    `${environment.strapiUrl}/products?filters[super_product][code]=${superProductCode}`;

  private superProductsGroupsMisApiUrl = (locale: AppLanguage) =>
    `${environment.strapiUrl}/super-product-group-mis?locale=${locale}&populate=super_products_group`;

  private superProductsGroupMisApiUrl = (
    code: StrapiSuperProductsGroupCode,
    locale: AppLanguage,
  ) =>
    `${environment.strapiUrl}/super-product-group-mis?locale=${locale}&populate=super_products_group&filters[super_products_group][code]=${code}`;

  private productsApiUrl = (locale: AppLanguage) =>
    `${environment.strapiUrl}/products?locale=${locale}`;

  private productVariantsApiUrl = (locale: AppLanguage) =>
    `${environment.strapiUrl}/variants?locale=${locale}`;

  /**
   * Accede a la api de superProductGroups y mapea los grupos de super productos
   * @returns
   */
  public loadSuperProductsGroups(
    locale = AppLanguage.EN,
  ): Observable<SuperProductsGroup[]> {
    return forkJoin([
      this.loadSuperProductsGroupsData(),
      // TODO extraer a super-product.service
      this.loadSuperProductsGroupsMis(locale),
    ]).pipe(
      map(([superProductsGroups, superProductsGroupsMis]) =>
        this.mapSuperProductsGroups(
          superProductsGroups,
          superProductsGroupsMis,
        ),
      ),
    );
  }

  /**
   * Accede a la api de superProductGroups y mapea los grupos de super productos
   * @returns
   */
  public loadSuperProductsGroup(
    code: StrapiSuperProductsGroupCode,
    locale = AppLanguage.EN,
  ): Observable<SuperProductsGroup> {
    return forkJoin([
      this.loadSuperProductsGroupData(code),
      this.loadSuperProductsGroupMis(code, locale),
    ]).pipe(
      map(
        ([superProductsGroup, superProductsGroupMis]) =>
          this.mapSuperProductsGroups(
            superProductsGroup,
            superProductsGroupMis,
          )[0],
      ),
    );
  }

  /**
   * Accede a la api de superProductGroups para obtener los datos.
   * @returns
   */
  private loadSuperProductsGroupsData(): Observable<StrapiSuperProductsGroups> {
    return this.httpClient.get<StrapiSuperProductsGroups>(
      `${this.superProductsGroupsApiUrl}`,
      {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      },
    );
  }

  /**
   * Accede a la api para obtener los datos de un superProductGroup.
   * @returns
   */
  private loadSuperProductsGroupData(
    code: StrapiSuperProductsGroupCode,
  ): Observable<StrapiSuperProductsGroups> {
    return this.httpClient.get<StrapiSuperProductsGroups>(
      `${this.superProductsGroupApiUrl(code)}`,
      {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      },
    );
  }

  /**
   * Accede a la api de superProductGroups para obtener los textos traducidos.
   * @returns
   */
  public loadSuperProductsGroupsMis(
    locale = AppLanguage.EN,
  ): Observable<StrapiSuperProductsGroupsMis> {
    return this.httpClient.get<StrapiSuperProductsGroupsMis>(
      `${this.superProductsGroupsMisApiUrl(locale)}`,
      {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      },
    );
    // .pipe(
    //   map((strapiProductTranslation) =>
    //     this.mapProductsTranslations(strapiProductTranslation),
    //   ),
    // );
  }
  /**
   * Accede a la api de superProductGroups para obtener los textos traducidos.
   * @returns
   */
  public loadSuperProductsGroupMis(
    code: StrapiSuperProductsGroupCode,
    locale = AppLanguage.EN,
  ): Observable<StrapiSuperProductsGroupsMis> {
    return this.httpClient.get<StrapiSuperProductsGroupsMis>(
      `${this.superProductsGroupMisApiUrl(code, locale)}`,
      {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      },
    );
  }

  /**
   * Accede a la api de variants para obtener los textos traducidos.
   * @returns
   */
  public loadProductVariants(
    locale = AppLanguage.EN,
  ): Observable<ProductVariant[]> {
    return this.httpClient
      .get<StrapiProductVariants>(`${this.productVariantsApiUrl(locale)}`, {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      })
      .pipe(
        map((strapiProductTranslation) =>
          this.mapProductsVariants(strapiProductTranslation),
        ),
      );
  }

  /**
   * Accede a la api de productos.
   * @returns
   */
  public loadProducts(locale = AppLanguage.EN): Observable<Product[]> {
    return this.httpClient
      .get<StrapiProducts>(`${this.productsApiUrl(locale)}`, {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      })
      .pipe(map((strapiProducts) => this.mapProducts(strapiProducts)));
  }

  /**
   * Accede a la api de superProductChannels y mapea los super productos
   * @returns
   */
  public loadSuperProducts(
    locale = AppLanguage.EN,
  ): Observable<SuperProduct[]> {
    const date = new Date().toISOString();
    return this.httpClient
      .get<StrapiSuperProductsChannel>(
        `${this.channelSuperProductsApiUrl(date)}`,
        {
          headers: { Authorization: `Bearer ${environment.strapiToken}` },
        },
      )
      .pipe(
        switchMap((strapiSuperProductsChannel) =>
          this.populateSuperProducts(strapiSuperProductsChannel, locale),
        ),
      );
  }

  // TODO tipado
  /**
   * Accede a la api de super producto y devuelve el objeto Strapi en crudo
   * @returns
   */
  public loadSuperProduct(
    superProductCode: string | null,
    locale = AppLanguage.EN,
  ): Observable<StrapiSuperProduct> {
    if (!superProductCode) {
      return of({ data: null });
    }
    return (
      this.httpClient
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(`${this.superProductApiUrl(superProductCode, locale)}`, {
          headers: { Authorization: `Bearer ${environment.strapiToken}` },
        })
        .pipe(
          map((response) => ({ data: response.data[0] })),          
        )
    );
  }

  /**
   * Accede a la api de productos y devuelve el objeto Strapi en crudo
   * @returns
   */
  public loadSuperProductProducts(
    superProductCode: string | null,
  ): Observable<StrapiProducts> {
    if (!superProductCode) {
      return of({ data: [] });
    }
    return this.httpClient.get<StrapiProducts>(
      `${this.superProductProductsApiUrl(superProductCode)}`,
      {
        headers: { Authorization: `Bearer ${environment.strapiToken}` },
      },
    );
  }

  private mapSuperProductsGroups(
    superProductsGroups: StrapiSuperProductsGroups,
    superProductsGroupsMis: StrapiSuperProductsGroupsMis,
  ): SuperProductsGroup[] {
    return superProductsGroups.data.map((superProductsGroup) => {
      const superProductsGroupMisData = superProductsGroupsMis.data.filter(
        (data) =>
          data.attributes.super_products_group?.data?.attributes.code ===
          superProductsGroup.attributes.code,
      )[0];

      return this.mapSuperProductsGroup(
        superProductsGroup,
        superProductsGroupMisData,
      );
    });
  }

  private mapSuperProductsGroup(
    superProductsGroup: StrapiSuperProductsGroupData,
    superProductsGroupMisData: StrapiSuperProductsGroupMisData,
  ): SuperProductsGroup {
    return {
      id: superProductsGroup.id,
      code: superProductsGroup.attributes.code,
      title: superProductsGroupMisData?.attributes.title || '',
      description: superProductsGroupMisData?.attributes.shortDescription || '',
      shortDescription:
        superProductsGroupMisData?.attributes.shortDescription || '',
      mainImageUrl: this.getImageUrl(superProductsGroup.attributes.image?.data),
      // TODO pedir campos Manuel
      analyticsId: superProductsGroup.attributes.analyticsId,
      analyticsName: superProductsGroup.attributes.analyticsName,
      analyticsCategory: superProductsGroup.attributes.analyticsCategory,
      activePrice: 0,
      reducedPrice: 0,
      fixDiscount: 0,
      percentageDiscount: 0,
      active: false,
      visible: false,
      products: this.mapSuperProductsGroupProducts(superProductsGroup),
    };
  }

  private mapSuperProductsGroupProducts(
    superProductsGroup: StrapiSuperProductsGroupData,
  ): SuperProductsGroupProduct[] {
    return superProductsGroup.attributes.group_entities.data
      .map((data) => this.mapSuperProductsGroupProduct(data))
      .filter((superProductGroup) => superProductGroup.code);
  }

  private mapSuperProductsGroupProduct(
    groupEntityData: StrapiGroupEntityData,
  ): SuperProductsGroupProduct {
    const code = groupEntityData.attributes.super_product.data
      ? groupEntityData.attributes.super_product.data?.attributes.code
      : groupEntityData.attributes.super_products_group.data?.attributes.code ||
        '';
    const type = groupEntityData.attributes.super_product.data
      ? SuperProductsGroupProductType.SuperProduct
      : SuperProductsGroupProductType.SuperProductGroup;
    return { code, type };
  }

  /**
   * Amplia la información sobre los super productos strapi
   * - Filtra para evitar los nulos (a veces llega el super_product a null)
   * - Obtiene la información sobre cada super producto
   * @param channelSuperProducts
   * @returns
   */
  private populateSuperProducts(
    channelSuperProducts: StrapiSuperProductsChannel,
    locale = AppLanguage.EN,
  ): Observable<SuperProduct[]> {
    return forkJoin(
      channelSuperProducts.data
        .filter(
          (channelSuperProductData) =>
            channelSuperProductData.attributes.super_product.data,
        )
        .map((channelSuperProductData) =>
          this.populateSuperProduct(channelSuperProductData, locale),
        ),
    );
  }

  /**
   * Amplia la información del super producto strapi
   * - Obtiene el detalle del super producto traducido
   * - Obtiene los productos hijo
   * @param channelSuperProductData
   * @returns
   */
  private populateSuperProduct(
    channelSuperProductData: StrapiSuperProductChannelData,
    locale = AppLanguage.EN,
  ): Observable<SuperProduct> {
    const superProductCode =
      channelSuperProductData.attributes.super_product.data?.attributes.code ||
      null;
    return forkJoin([
      this.loadSuperProduct(superProductCode, locale),
      this.loadSuperProductProducts(superProductCode),
    ]).pipe(
      map(([superProduct, products]) =>
        this.mapSuperProduct(channelSuperProductData, superProduct, products),
      ),
    );
  }

  /**
   * Mapea los datos del super producto strapi al super producto de la aplicación
   * @param channelData
   * @param strapiSuperProduct
   * @param strapiProducts
   * @returns
   */
  private mapSuperProduct(
    strapiChannelSuperProductData: StrapiSuperProductChannelData,
    strapiSuperProduct: StrapiSuperProduct,
    strapiProducts: StrapiProducts,
  ): SuperProduct {
    // console.log('strapi super product', strapiSuperProduct);
    return {
      code:
        strapiChannelSuperProductData.attributes.super_product.data?.attributes
          .code || '',
      active: strapiChannelSuperProductData.attributes.active,
      visible: strapiChannelSuperProductData.attributes.visible,
      title: strapiSuperProduct.data?.attributes.title || '',
      slug: strapiSuperProduct.data?.attributes.slug || '',
      routeCode: strapiSuperProduct.data?.attributes.routeCode || '',
      products: this.mapProducts(strapiProducts),
      subProducts: strapiProducts.data.map(
        (productData) => productData.attributes.code,
      ),
      description:
        strapiSuperProduct.data?.attributes.description[0].children[0].text ||
        '',
      shortDescription:
        strapiSuperProduct.data?.attributes.shortDescription[0].children[0]
          .text || '',
      summary:
        strapiSuperProduct.data?.attributes.Summary[0].children[0].text || '',
      headerImageUrl: this.getImageUrl(
        strapiSuperProduct.data?.attributes.headerImage.data,
      ),
      mainImageUrl: this.getImageUrl(
        strapiSuperProduct.data?.attributes.mainImage.data,
      ),
      sliderImagesUrls:
        strapiSuperProduct.data?.attributes?.images?.data?.map((image) =>
          this.getImageUrl(image),
        ) || [],
      activePrice: 0,
      reducedPrice: 0,
      fixDiscount: 0,
      percentageDiscount: 0,
      moduleSlider: strapiSuperProduct.data?.attributes.ModuleSlider || false,
      moduleSliderTitle:
        strapiSuperProduct.data?.attributes.ModuleSliderTitle || '',
      moduleBox: strapiSuperProduct.data?.attributes.ModuleBox || false,
      moduleAccordion:
        strapiSuperProduct.data?.attributes.ModuleAccordion || false,
      moduleBoxTitle: strapiSuperProduct.data?.attributes.ModuleBoxTitle || '',
      moduleAccordionTitle:
        strapiSuperProduct.data?.attributes.ModuleAccordionTitle || '',
      moduleBoxes:
        strapiSuperProduct.data?.attributes.ModuleBoxes?.data.map((moduleBox) =>
          this.mapModuleBox(moduleBox),
        ) || [],
      analyticsId: strapiSuperProduct.data?.attributes.analyticsId || '',
      analyticsName: strapiSuperProduct.data?.attributes.analyticsName || '',
      type: null,
      analyticsCategory:
        strapiSuperProduct.data?.attributes.analyticsCategory || '',
      metaTitle: strapiSuperProduct.data?.attributes.metaTitle || '',
      metaDescription:
        strapiSuperProduct.data?.attributes.metaDescription || '',
      metaKeywords: strapiSuperProduct.data?.attributes.metaKeywords || '',
    };
  }

  private getImageUrl(
    strapiImageData: StrapiImageData | null | undefined,
  ): string {
    const url = strapiImageData?.attributes?.url;
    return url ? url : '';
  }

  private mapModuleBox(strapiModuleBoxData: StrapiModuleBoxData): ModuleBox {
    console.log('MODULE BOXES ====== ', strapiModuleBoxData);
    return {
      title: strapiModuleBoxData.attributes.Title,
      tag: strapiModuleBoxData.attributes.Tag,
      link: strapiModuleBoxData.attributes.Link,
      enabled: strapiModuleBoxData.attributes.Enabled,
      images:
        strapiModuleBoxData.attributes.Images.data?.map((strapiImage) =>
          this.getImageUrl(strapiImage),
        ) || [],
    };
  }

  private mapProducts(strapiProducts: StrapiProducts): Product[] {
    return strapiProducts.data.map((strapiProductData) =>
      this.mapProduct(strapiProductData),
    );
  }

  private mapProduct(strapiProductData: StrapiProductData): Product {
    return {
      code: strapiProductData.attributes.code,
      title: strapiProductData.attributes.title,
    };
  }

  private mapProductsVariants(
    strapiProductVariants: StrapiProductVariants,
  ): ProductVariant[] {
    return strapiProductVariants.data.map((strapiProductVariantData) =>
      this.mapProductVariant(strapiProductVariantData),
    );
  }
  private mapProductVariant(
    strapiProductVariantData: StrapiProductVariantData,
  ): ProductVariant {
    return {
      code: strapiProductVariantData.attributes.code,
      title: strapiProductVariantData.attributes.title,
      shortTitle: strapiProductVariantData.attributes.shortTitle,
      description: strapiProductVariantData.attributes.description,
    };
  }
}
